@import '../mixins';

.ui {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  .rules-link {
    color: #fff;
    display: block;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    text-decoration-line: underline;
    font-family: 'Open Sans', sans-serif; }
  @include mediaRapture('mobile') {
    .game-view & {
      height: pxvw(70); }
    .screen {
      .decor {
        position: absolute;
        background: no-repeat center/contain;
        &__lt {
          top: 0;
          left: 0;
          width: pxvw(93);
          height: pxvw(112);
          background-image: url(../assets/img/fryingLT.png); }
        &__lc {
          left: 0;
          top: pxvw(244);
          width: pxvw(32);
          height: pxvw(85);
          background-image: url(../assets/img/fryingLC.png); }
        &__lb {
          bottom: 0;
          left: pxvw(6);
          width: pxvw(39);
          height: pxvw(151);
          background-image: url(../assets/img/fryingLB.png); }
        &__rt {
          top: 0;
          right: 0;
          width: pxvw(40);
          height: pxvw(208);
          background-image: url(../assets/img/fryingRT.png); }
        &__rc {
          right: 0;
          top: pxvw(236);
          width: pxvw(111);
          height: pxvw(210);
          background-image: url(../assets/img/fryingRC.png); }
        &__rb {
          right: 0;
          bottom: 0;
          width: pxvw(228);
          height: pxvw(87);
          background-image: url(../assets/img/fryingRB.png); } } }
    .rules-link {
      font-size: pxvw(12);
      line-height: pxvw(24); } }
  @include mediaRapture('desktop') {
    .screen {
      .decor {
        position: absolute;
        background: no-repeat center/contain;
        &__lt {
          top: 0;
          left: 0;
          width: pxvh(477);
          height: pxvh(232);
          background-image: url(../assets/img/xlDecorLT.png); }
        &__lc {
          left: 0;
          top: pxvh(209);
          width: pxvh(99);
          height: pxvh(374);
          background-image: url(../assets/img/xlDecorLC.png); }
        &__lb {
          left: 0;
          bottom: 0;
          width: pxvh(115);
          height: pxvh(149);
          background-image: url(../assets/img/xlDecorLB.png); }
        &__rt {
          top: 0;
          right: 0;
          width: pxvh(570);
          height: pxvh(206);
          background-image: url(../assets/img/xlDecorRT.png); }
        &__rc {
          right: 0;
          width: pxvh(83);
          bottom: pxvh(179);
          height: pxvh(265);
          background-image: url(../assets/img/fryingRC.png); }
        &__rb {
          right: 0;
          bottom: 0;
          width: pxvh(544);
          height: pxvh(225);
          background-image: url(../assets/img/xlDecorRB.png); }
        &__b {
          bottom: 0;
          left: pxvh(259);
          width: pxvh(500);
          height: pxvh(234);
          background-image: url(../assets/img/xlDecorB.png); } } }
    .rules-link {
      font-size: pxvh(18);
      line-height: pxvh(24); } } }

//.ui .screen
//  top: 0
//  left: 0
//  width: 100%
//  height: 100%
//  display: none
//  position: relative
//.ui.title .title-screen,
//.ui.frying .frying-screen,
//.ui.result .result-screen,
//.ui.catcher .catcher-screen
//  display: block

.ui .screen {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  will-change: opacity, transform;
  transition: 400ms ease-in;
  transition-property: opacity, transform;
  &-entering {
    opacity: 1;
    z-index: 1;
    transform: scale(1); }
  &-entered {
    opacity: 1;
    z-index: 1;
    transform: scale(1); }
  &-exiting {
    opacity: 0; }
  &-exited {
    opacity: 0;
    transform: scale(0); } }
//.ui .screen.title-screen
//  transition-timing-function: cubic-bezier(.47,1.64,.41,.8)
