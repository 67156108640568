@import '../../mixins';

.result_winners {
  overflow-y: auto;
  @include mediaRapture('desktop') {
    padding-bottom: pxvh(30); } }

.winners {
  &__title {
    color: #fff;
    text-align: center;
    box-sizing: border-box;
    letter-spacing: 0.05em;
    font-family: 'Peach Milk 2.0', sans-serif; }
  &__block:first-child &__title {
    color: #282828; }
  &__tip {
    color: #282828;
    font-style: normal;
    text-align: center;
    font-weight: normal;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    a {
      color: #0066FF;
      text-decoration: none; } }
  @include mediaRapture('mobile') {
    &__title {
      font-size: pxvw(28);
      line-height: pxvw(32); }
    &__block {
      margin-top: pxvw(40);
      &:first-child {
        margin-top: 0; } }
    &__block:first-child &__title {
      margin: 0 auto;
      width: pxvw(280);
      height: pxvw(114);
      padding: pxvw(30) pxvw(36) 0;
      background: url(../../assets/img/winnersTitleBg.svg) no-repeat center/contain; }
    &__list {
      width: pxvw(280);
      margin: pxvw(10) auto 0; }
    &__tip {
      width: pxvw(280);
      height: pxvw(96);
      font-size: pxvw(12);
      line-height: pxvw(19);
      margin: pxvw(10) auto 0;
      padding: pxvw(18) pxvw(15);
      background: url(../../assets/img/winnersTipBg.svg) no-repeat center/contain; }
    .result__play-again {
      margin-top: pxvw(30); } }
  @include mediaRapture('desktop') {
    padding-top: pxvh(70);
    &__title {
      font-size: pxvh(40);
      line-height: pxvh(40); }
    &__block {
      margin-top: pxvh(30);
      &:first-child {
        margin-top: 0; } }
    &__block:first-child &__title {
      margin: 0 auto;
      width: pxvh(474);
      height: pxvh(120);
      padding-top: pxvh(40);
      background: url(../../assets/img/xlWinnersTitleBg.svg) no-repeat center/contain; }
    &__list {
      width: pxvh(700);
      margin: pxvh(30) auto 0; }
    &__block:first-child &__list {
      padding-top: pxvh(20); }
    &__tip {
      width: pxvh(609);
      height: pxvh(106);
      font-size: pxvh(18);
      line-height: pxvh(28);
      margin: pxvh(30) auto 0;
      padding: pxvh(20) pxvh(55);
      background: url(../../assets/img/xlWinnersTipBg.svg) no-repeat center/contain; }
    .result__play-again {
      margin-top: pxvh(30); } } }
