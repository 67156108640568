@import '../../mixins';

.catcher-screen {
  &__progress {
    position: absolute;
    overflow: hidden;
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: relative;
      border-radius: 100px;
      box-sizing: border-box;
      border: solid #fff; }
    &-bar {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      background: #CD423B;
      transform: scaleX(1);
      will-change: transform;
      transform-origin: 0 50%;
      transition: transform 500ms ease; } }
  @include mediaRapture('mobile') {
    &__progress {
      top: pxvw(30);
      left: pxvw(18);
      width: pxvw(158);
      height: pxvw(14);
      &-bar {
        margin-top: pxvw(2);
        margin-left: pxvw(2);
        width: calc(100% - #{pxvw(4)});
        height: calc(100% - #{pxvw(4)}); } }
    &__round {
      top: pxvw(70);
      left: pxvw(85); } }
  @include mediaRapture('desktop') {
    &__progress {
      position: relative;
      width: pxvh(438);
      height: pxvh(14);
      margin: pxvh(59) auto;
      &-bar {
        margin-top: pxvh(2);
        margin-left: pxvh(2);
        width: calc(100% - #{pxvh(4)});
        height: calc(100% - #{pxvh(4)}); } }
    &__round {
      top: pxvh(101);
      left: calc(50% - #{pxvh(195 / 2)}); } } }

