@import '../../../mixins';

.grill {
  position: absolute;
  background: url(../../../assets/img/grill.png) no-repeat center/contain;
  &__zone {
    position: absolute; }
  &__switch {
    color: #fff;
    position: absolute;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    filter: drop-shadow(0 -10px 10px #00FFFF);
    font-family: 'Peach Milk 2.0', sans-serif;
    transform: scale(1);
    will-change: transform;
    transition: transform 200ms ease-in-out;
    background: no-repeat center/contain;
    &:hover {
      animation: none !important; }
    .grill__zone_1 & {
      background-image: url(../../../assets/img/switchLeftBtn.svg); }
    .grill__zone_2 & {
      background-image: url(../../../assets/img/switchRightBtn.svg); }
    .switch & {
      animation: pulseBaitAnimation 2s 2s infinite; } }
  .kebab {
    width: 100%;
    height: 100%;
    &__img {
      width: 100%;
      height: 100%;
      display: block;
      transform: scaleX(1);
      will-change: transform;
      background: no-repeat center/contain;
      transition: transform 200ms ease-in-out; } }
  .kebab_1 .kebab__img {
    filter: brightness(0.9);
    transform: scaleX(-1); }
  .kebab_2 .kebab__img {
    filter: brightness(0.8);
    transform: scaleX(1); }
  .kebab_3 .kebab__img {
    filter: brightness(0.75);
    transform: scaleX(-1); }
  .add-kebab {
    transform: scale(1);
    will-change: transform;
    transition: transform 200ms ease-in-out;
    background: url(../../../assets/img/addKebab.svg) no-repeat center/contain; }
  .progress {
    z-index: 1;
    display: flex;
    position: absolute;
    flex-direction: column;
    &__bar {
      flex-grow: 1; }
    &__icon {
      flex-shrink: 0;
      border-radius: 50%;
      position: relative;
      background: #fff url(../../../assets/img/fryProgressIcon.svg) no-repeat center/contain; }
    &__arrow {
      top: 0;
      position: absolute;
      will-change: transform;
      transform: translateY(0px);
      transition: transform 100ms linear;
      background: url(../../../assets/img/fryingProgressArrow.svg) no-repeat center/contain; } }
  .progress_boosted .progress__bar {
    background: #218DF9 !important; }
  @include mediaRapture('mobile') {
    top: pxvw(106);
    left: pxvw(56);
    width: pxvw(209);
    height: pxvw(143);
    &__zone {
      top: pxvw(-14);
      width: pxvw(82);
      height: pxvw(186);
      &_1 {
        left: pxvw(9); }
      &_2 {
        right: pxvw(1.5); } }
    &__switch {
      width: pxvw(93);
      height: pxvw(36);
      bottom: pxvw(-60);
      font-size: pxvw(14);
      line-height: pxvw(10);
      &:active {
        transform: scale(1.15); }
      .grill__zone_1 & {
        left: pxvw(-40); }
      .grill__zone_2 & {
        left: pxvw(-20); } }
    .add-kebab {
      width: pxvw(48);
      height: pxvw(48);
      transform: scale(1);
      margin: pxvw(63) auto 0;
      &:active {
        transform: scale(1.2); } }
    .progress {
      width: pxvw(18);
      height: pxvw(169);
      &__bar {
        border-radius: pxvw(100);
        box-shadow: inset 0 0 0 pxvw(3) #fff;
        filter: drop-shadow(0 pxvw(40) pxvw(30) rgba(0, 0, 0, 0.25)) drop-shadow(0 pxvw(40) pxvw(5) rgba(0, 0, 0, 0.15)); }
      &__icon {
        width: pxvw(18);
        height: pxvw(18);
        margin-top: pxvw(8); }
      &__arrow {
        top: pxvw(-15);
        left: pxvw(-23);
        width: pxvw(23);
        height: pxvw(23);
        filter: drop-shadow(0 pxvw(4) pxvw(4) rgba(0, 0, 0, 0.7)); } } }

  @include mediaRapture('desktop') {
    z-index: 1;
    width: pxvh(303);
    height: pxvh(208);
    position: relative;
    margin: pxvh(128) auto 0;
    &__zone {
      top: pxvh(-19);
      width: pxvh(103);
      height: pxvh(271);
      &_1 {
        left: pxvh(26); }
      &_2 {
        right: pxvh(8); } }
    &__switch {
      width: pxvh(136);
      height: pxvh(53);
      bottom: pxvh(-77);
      font-size: pxvh(20);
      line-height: pxvh(10);
      &:hover {
        transform: scale(1.15); }
      .grill__zone_1 & {
        left: pxvh(-24); }
      .grill__zone_2 & {
        left: pxvh(-24); } }
    .add-kebab {
      width: pxvh(70);
      height: pxvh(70);
      margin: pxvh(91) auto 0;
      &:hover {
        transform: scale(1.15); } }
    .progress {
      width: pxvh(26);
      height: pxvh(247);
      &__bar {
        border-radius: pxvh(100);
        box-shadow: inset 0 0 0 pxvh(3) #fff;
        filter: drop-shadow(0 pxvh(40) pxvh(30) rgba(0, 0, 0, 0.25)) drop-shadow(0 pxvh(40) pxvh(5) rgba(0, 0, 0, 0.15)); }
      &__icon {
        width: pxvh(26);
        height: pxvh(26);
        margin-top: pxvh(11); }
      &__arrow {
        top: pxvh(-20);
        left: pxvh(-34);
        width: pxvh(34);
        height: pxvh(34);
        filter: drop-shadow(0 pxvh(4) pxvh(4) rgba(0, 0, 0, 0.7)); } } } }
