@import '../../mixins';

.frying-screen {
  .header {
    display: none; }
  .booster {
    display: block;
    position: absolute;
    transform: scale(1);
    will-change: transform;
    transition: transform 200ms ease-in-out;
    &_active {
      animation: pulseBaitAnimation 2s 2s infinite; }
    &:after,
    &:before {
      content: '';
      display: block;
      position: absolute; }
    &_beer:after {
      display: none; }
    &:after {
      background: url(../../assets/img/tornadoBooster.svg) no-repeat center/contain; }
    &__icon {
      display: block;
      position: absolute; }
    &__btn {
      color: #C4C4C4;
      display: block;
      text-align: center;
      position: absolute;
      font-style: normal;
      font-weight: normal;
      letter-spacing: 0.1em;
      box-sizing: border-box;
      text-transform: uppercase;
      background: url(../../assets/img/beerBtnDisabled.svg) no-repeat center/contain;
      font-family: 'Peach Milk 2.0', sans-serif; }
    &__progress {
      display: block;
      overflow: hidden;
      position: absolute;
      &:after,
      &-bar {
        left: 0;
        bottom: 0;
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        position: absolute; }
      &:after {
        border: solid #fff;
        box-sizing: border-box; }
      &-bar {
        transform: scaleY(1);
        will-change: transform;
        transform-origin: 50% 100%;
        transition: transform 500ms ease-out; } } }
  .booster_beer {
    &:hover {
      animation: none !important; } }
  .booster_energy.booster_active .booster__btn {
    color: #218DF9;
    background: url(../../assets/img/energyBtn.svg) no-repeat center/contain; }
  .booster_beer.booster_active .booster__btn {
    color: #FCB608;
    background: url(../../assets/img/beerBtn.svg) no-repeat center/contain; }
  .booster_beer:before {
    background: #FCB608; }
  .booster_beer .booster__icon {
    background: url(../../assets/img/beerIcon.svg) no-repeat center/contain; }
  .booster_energy:before {
    background: #00FFFF; }
  .booster_energy .booster__icon {
    background: url(../../assets/img/energyIcon.png) no-repeat center/contain; }
  .booster_beer .booster__progress-bar {
    background: #FF7A00; }
  .booster_energy .booster__progress-bar {
    background: #00C2FF; }

  @include mediaRapture('mobile') {
    background: url(../../assets/img/fryingBg.jpg) no-repeat center/cover;
    &__round {
      top: pxvw(20);
      left: pxvw(20); }
    .booster {
      bottom: pxvw(75);
      width: pxvw(90);
      height: pxvw(101);
      &:active {
        transform: scale(1.2); }
      &:before {
        top: 50%;
        left: 50%;
        width: pxvw(10);
        height: pxvw(10); }
      &_beer:before {
        box-shadow: 0 0 pxvw(30) pxvw(14) #FCB608; }
      &_energy:before {
        box-shadow: 0 0 pxvw(30) pxvw(16) #00FFFF; }
      &:after {
        top: pxvw(-24);
        right: pxvw(0);
        width: pxvw(28);
        height: pxvw(24); }
      &_beer {
        left: pxvw(47); }
      &_energy {
        right: pxvw(58); }
      &__btn {
        left: pxvw(16);
        bottom: pxvw(-3);
        width: pxvw(68);
        height: pxvw(28);
        font-size: pxvw(12);
        line-height: pxvw(10);
        padding-top: pxvw(11); }
      &__progress {
        top: pxvw(3);
        right: pxvw(5);
        width: pxvw(14);
        height: pxvw(72);
        &:after {
          border-radius: pxvw(30); }
        &-bar {
          margin-left: pxvw(2);
          margin-bottom: pxvw(2);
          border-radius: pxvw(30);
          width: calc(100% - #{pxvw(4)});
          height: calc(100% - #{pxvw(4)}); } } }
    .booster_beer .booster__icon {
      top: pxvw(8);
      left: pxvw(24);
      width: pxvw(43);
      height: pxvw(82); }
    .booster_energy .booster__icon {
      top: pxvw(-2);
      left: pxvw(16);
      width: pxvw(69);
      height: pxvw(100); }
    .rules-link {
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      bottom: pxvw(16); } }
  @include webview() {
    .rules-link {
      bottom: pxvw(2);
      font-size: pxvw(10); }
    .booster {
      bottom: pxvw(30); } }
  @include mediaRapture('desktop') {
    background: url(../../assets/img/xlFryingBg.jpg) no-repeat center/cover;
    .rules-link {
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      bottom: pxvh(66); }
    &__round {
      top: pxvh(38);
      left: pxvh(163); }
    .header {
      margin-top: 0;
      display: flex;
      padding-top: pxvh(38); }
    .boosters {
      width: pxvh(779);
      height: pxvh(190);
      position: relative;
      margin: pxvh(-214) auto 0; }
    .booster {
      top: 0;
      bottom: pxvh(75);
      width: pxvh(169);
      height: pxvh(190);
      &:hover {
        transform: scale(1.15); }
      &:before {
        top: 50%;
        left: 50%;
        width: pxvh(10);
        height: pxvh(10); }
      &_beer:before {
        box-shadow: 0 0 pxvh(44) pxvh(40) #FCB608; }
      &_energy:before {
        box-shadow: 0 0 pxvh(44) pxvh(40) #00FFFF; }
      &:after {
        top: pxvh(-40);
        right: pxvh(12);
        width: pxvh(46);
        height: pxvh(40); }
      &_beer {
        left: 0; }
      &_energy {
        right: 0; }
      &__btn {
        left: pxvh(29);
        bottom: pxvh(0);
        width: pxvh(128);
        height: pxvh(52);
        font-size: pxvh(20);
        line-height: pxvh(18);
        padding-top: pxvh(20); }
      &__progress {
        top: pxvh(6);
        right: pxvh(23);
        width: pxvh(22);
        height: pxvh(132);
        &:after {
          border-radius: pxvh(30); }
        &-bar {
          margin-left: pxvh(2);
          margin-bottom: pxvh(2);
          border-radius: pxvh(30);
          width: calc(100% - #{pxvh(4)});
          height: calc(100% - #{pxvh(4)}); } } }
    .booster_beer .booster__icon {
      top: pxvh(30);
      left: pxvh(48);
      width: pxvh(71);
      height: pxvh(135); }
    .booster_energy .booster__icon {
      top: pxvh(36);
      left: pxvh(26);
      width: pxvh(92);
      height: pxvh(132); } } }

@keyframes scoreParticle {
  from {
    background-position-y: 0px; }
  to {
    background-position-y: -10000px; } }

@keyframes boosterActive {
  20% {
    transform: scale(1.1); }
  30% {
    transform: scale(1.05); }
  40% {
    transform: scale(1.15); }
  50% {
    transform: scale(1.1); }
  60% {
    transform: scale(1.2); }
  100% {
    transform: scale(1); } }
@keyframes pulseBaitAnimation {
  0% {
    transform: scale(1); }
  5% {
    transform: scale(1.12); }
  10% {
    transform: scale(1.07); }
  15% {
    transform: scale(1.22); }
  30% {
    transform: scale(1); } }
