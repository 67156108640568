$factor: 0;

$breakpoints: ("mobile": 793px, "desktop": 794px);
$breakpointFactors: ("mobile": 320, "desktop": 768);

@mixin mediaRapture($breakpoint) {
  $max-width: map_get($breakpoints, $breakpoint);
  $factor: map_get($breakpointFactors, $breakpoint) !global;
  @if($breakpoint == 'mobile') {
    @media(max-width: $max-width) {
      @content; } }
  @else {
    @media(min-width: $max-width) {
      @content; } } }

@function pxvw($px, $fac: $factor) {
  @return $px / $factor * 100vw; }
@function pxvh($px, $fac: $factor) {
  @return $px / $factor * 100vh; }

@mixin webview() {
  $factor: map_get($breakpointFactors, "mobile") !global;
  @media(min-aspect-ratio: 375/640) and (max-width: 793px) {
    @content; } }
