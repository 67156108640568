@import '../../mixins';

$authProviders: 'vk', 'facebook', 'twitter', 'google';
$shareProviders: 'vkontakte', 'facebook', 'telegram', 'whatsapp';

.result-screen {
  background: url(../../assets/img/fryingBg.jpg) no-repeat center/cover;
  .result_rating {
    overflow-y: auto; }
  .result {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    box-sizing: border-box;
    &__reason {
      color: #282828;
      margin: 0 auto;
      font-style: normal;
      text-align: center;
      position: relative;
      font-weight: normal;
      letter-spacing: 0.05em;
      box-sizing: border-box;
      font-family: 'Peach Milk 2.0', sans-serif;
      &:before {
        content: '';
        display: block;
        position: absolute;
        background: url(../../assets/img/noAuthDecor.svg) no-repeat center/contain; } }
    &__main {
      box-sizing: border-box; }
    &__content {
      flex-shrink: 0;
      position: relative;
      box-sizing: border-box;
      &:before {
        content: '';
        display: block;
        position: absolute; } }
    &__title {
      color: #282828;
      text-align: center;
      letter-spacing: 0.05em;
      font-family: 'Peach Milk 2.0', sans-serif; }
    &__subtitle {
      color: #282828;
      text-align: center;
      font-style: normal;
      font-weight: normal;
      font-family: 'Open Sans', sans-serif; }
    &__score {
      display: flex;
      justify-content: center;
      &-title {
        color: #282828;
        font-style: normal;
        font-weight: normal;
        font-family: 'Open Sans', sans-serif; }
      &-value {
        color: #070E27;
        font-style: normal;
        font-weight: normal;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        font-family: 'Peach Milk 2.0', sans-serif; }
      &-icon {
        flex-shrink: 0;
        background: url(../../assets/img/coin.svg) no-repeat center/contain; } }
    &__text {
      color: #282828;
      font-style: normal;
      text-align: center;
      font-weight: normal;
      font-family: 'Open Sans', sans-serif; }
    &__header {
      color: #282828;
      text-align: center;
      font-style: normal;
      position: relative;
      font-weight: normal;
      letter-spacing: 0.1em;
      font-family: 'Peach Milk 2.0', sans-serif; }
    &__social {
      display: flex;
      flex-shrink: 0;
      justify-content: center;
      &-btn {
        will-change: transform;
        background: no-repeat center/contain;
        transition: transform 200ms ease-in-out;
        &:last-child {
          margin-right: 0; } } }
    &__play-again {
      color: #fff;
      flex-shrink: 0;
      font-style: normal;
      text-align: center;
      font-weight: normal;
      letter-spacing: 0.1em;
      will-change: transform;
      text-transform: uppercase;
      transition: transform 200ms ease-in-out;
      font-family: 'Peach Milk 2.0', sans-serif;
      background: url(../../assets/img/playAgaingBtn.svg) no-repeat center/contain;
      &.thin {
        background: transparent;
        text-decoration: underline; } }
    &__rating {
      position: relative;
      box-sizing: border-box; } }

  @include mediaRapture('mobile') {
    .result {
      padding-top: pxvw(32);
      &__reason {
        flex-shrink: 0;
        width: pxvw(280);
        height: pxvw(106);
        font-size: pxvw(24);
        line-height: pxvw(32);
        padding-top: pxvw(61);
        background: url(../../assets/img/resultReasonBg.svg) no-repeat center/contain;
        &:before {
          top: pxvw(-13);
          left: pxvw(106);
          width: pxvw(62);
          height: pxvw(64); } }
      &__main {
        flex-shrink: 0;
        width: pxvw(280);
        height: pxvw(136);
        margin: pxvw(8) auto 0;
        padding-top: pxvw(24);
        background: url(../../assets/img/resultMainBg.svg) no-repeat center/contain; }
      &__title {
        font-size: pxvw(24);
        line-height: pxvw(32); }
      &__subtitle {
        width: pxvw(184);
        font-size: pxvw(16);
        line-height: pxvw(24);
        margin: pxvw(10) auto 0; }
      &__content {
        margin: 0 auto;
        width: pxvw(286);
        height: pxvw(386);
        padding-top: pxvw(67);
        filter: drop-shadow(0px pxvw(20) pxvw(15) rgba(0, 0, 0, 0.25));
        background: url(../../assets/img/noAuthBgMobile.svg) no-repeat center/contain;
        &:before {
          top: pxvw(-7);
          left: pxvw(108);
          width: pxvw(70);
          height: pxvw(64);
          background: url(../../assets/img/hasAuthDecor.svg) no-repeat center/contain; } }
      &__score {
        margin: pxvw(12) auto 0;
        &-title {
          font-size: pxvw(12);
          line-height: pxvw(22); }
        &-value {
          font-size: pxvw(22);
          line-height: pxvw(24);
          margin-top: pxvw(2); }
        &-icon {
          width: pxvw(31);
          height: pxvw(37);
          margin-top: pxvw(8);
          margin-right: pxvw(13);
          filter: drop-shadow(0 0 pxvh(7) #FFD600); } }
      &__text {
        font-size: pxvw(14);
        margin-top: pxvw(27);
        line-height: pxvw(20);
        box-sizing: border-box; }
      &__header {
        margin-top: pxvw(15);
        font-size: pxvw(22);
        line-height: pxvw(32); }
      &__social {
        margin-top: pxvw(10);
        &-btn {
          width: pxvw(48);
          height: pxvw(48);
          margin-right: pxvw(10);
          &:active {
            transform: scale(1.15); } } }
      &__play-again {
        width: pxvw(248);
        height: pxvw(64);
        font-size: pxvw(28);
        line-height: pxvw(32);
        margin: auto auto 0;
        &:active {
          transform: scale(1.15); }
        &.thin {
          font-size: pxvw(22);
          height: pxvw(32); } }
      &__rules {
        margin: pxvw(25) auto pxvw(20); }
      &__rating {
        padding: 0 pxvw(20);
        margin: pxvw(10) 0 pxvw(30); } }

    .result_no-auth .result__text {
      padding-top: pxvw(6); }
    .result_no-auth .result__content:before {
        background-image: url(../../assets/img/noAuthDecor.svg); }
    .result_no-auth .result__text {
      width: pxvw(236);
      height: pxvw(60);
      margin: pxvw(16) auto 0;
      background: url(../../assets/img/noAuthHighlight.svg) no-repeat center center/contain; }

    .result_has-auth .result__text {
      display: flex;
      width: pxvw(219);
      height: pxvw(57);
      font-size: pxvw(12);
      align-items: center;
      justify-content: center;
      line-height: pxvw(17);
      margin: pxvw(15) auto 0;
      background: url(../../assets/img/hasAuthHighlight.svg) no-repeat center/contain; }

    .result_rating {
      padding-top: pxvw(74); }
    .result_rating .result__content {
      width: pxvw(280);
      height: pxvw(169);
      padding-top: pxvw(58);
      background: url(../../assets/img/ratingBgMobile.svg) no-repeat center/contain;
      &:before {
        top: pxvw(-41);
        left: pxvw(59);
        width: pxvw(164);
        height: pxvw(79);
        background-image: url(../../assets/img/projectLogo.svg); } }
    .result_rating .result__title {
      margin: 0 auto;
      width: pxvw(206);
      font-size: pxvw(18);
      line-height: pxvw(24); }
    .result_rating .result__subtitle {
      width: pxvw(243);
      margin-top: pxvw(7);
      font-size: pxvw(12);
      line-height: pxvw(19); }
    .result_rating .result__play-again.thin {
      margin-top: pxvw(28); } }

  @include webview() {
    .result {
      padding-top: pxvw(22);
      &__play-again.thin {
        margin-top: pxvw(8); }
      &__rules {
        margin-top: pxvw(6); } } }

  @include mediaRapture('desktop') {
    background: url(../../assets/img/xlFryingBg.jpg) no-repeat center/cover;
    .result_rating {
      padding-bottom: pxvh(77); }
    .result {
      &__reason {
        flex-shrink: 0;
        width: pxvh(566);
        height: pxvh(99);
        font-size: pxvh(40);
        line-height: pxvh(40);
        padding-top: pxvh(47);
        margin-top: pxvh(79);
        background: url(../../assets/img/xlResultReasonBg.svg) no-repeat center/contain;
        &:before {
          top: pxvh(-49);
          left: pxvh(247);
          width: pxvh(74);
          height: pxvh(76); } }
      &__main {
        flex-shrink: 0;
        width: pxvh(600);
        height: pxvh(205);
        margin: pxvh(10) auto 0;
        padding-top: pxvh(24);
        background: url(../../assets/img/xlResultMainBg.svg) no-repeat center/contain; }
      &__main .result__title {
        margin-top: 0 !important; }
      &__title {
        margin-top: pxvh(58);
        font-size: pxvh(40);
        line-height: pxvh(40); }
      &__subtitle {
        width: pxvh(406);
        font-size: pxvh(18);
        line-height: pxvh(28);
        margin: pxvh(20) auto 0; }
      &__text {
        text-align: left;
        font-size: pxvh(18);
        line-height: pxvh(28);
        padding-left: pxvh(14); }
      &__header {
        font-size: pxvh(30);
        margin-top: pxvh(34);
        line-height: pxvh(40); }
      &__rules {
        margin-top: pxvh(77); }
      &__play-again {
        width: pxvh(248);
        height: pxvh(64);
        font-size: pxvh(28);
        line-height: pxvh(32);
        margin: pxvh(32) auto 0;
        &:hover {
          transform: scale(1.15); }
        &.thin {
          width: auto;
          height: pxvh(32); } }
      &__row {
        display: flex;
        margin: pxvh(20) auto 0;
        width: pxvh(524);
        align-items: center;
        justify-content: center; }
      &__content {
        width: pxvh(692);
        height: pxvh(437);
        margin: pxvh(100) auto 0;
        background: url(../../assets/img/xlNoAuthBg.svg) no-repeat center/contain;
        &:before {
          background: no-repeat center/contain; } }
      &__score {
        margin: pxvh(6) pxvh(25) 0 0;
        &-title {
          font-size: pxvh(18);
          line-height: pxvh(22); }
        &-value {
          font-size: pxvh(40);
          line-height: pxvh(40);
          margin-top: pxvh(10); }
        &-icon {
          width: pxvh(58);
          height: pxvh(69);
          margin-right: pxvh(21);
          filter: drop-shadow(0 0 pxvh(10) #FFD600); } }
      &__social {
        margin-top: pxvh(24);
        &-btn {
          width: pxvh(64);
          height: pxvh(64);
          margin-right: pxvh(20);
          &:hover {
            transform: scale(1.15); } } }
      &__rating {
        width: pxvh(700);
        margin: pxvh(10) auto 0; } }
    .result_no-auth .result__content {
      &:before {
        top: pxvh(-40);
        left: pxvh(294);
        width: pxvh(74);
        height: pxvh(76);
        background-image: url(../../assets/img/noAuthDecor.svg); } }
    .result_no-auth .result__text {
      width: pxvh(250);
      height: pxvh(85);
      font-size: pxvh(18);
      line-height: pxvh(26);
      box-sizing: border-box;
      padding: pxvh(12) 0 0 pxvh(17);
      background: url(../../assets/img/xlNoAuthHighlight.svg) no-repeat center/contain; }
    .result_no-auth .result__text.thin {
      padding: 0 0 0 pxvh(8); }

    .result_has-auth .result__content {
      &:before {
        top: pxvh(-40);
        left: pxvh(305);
        width: pxvh(83);
        height: pxvh(76);
        background: url(../../assets/img/hasAuthDecor.svg) no-repeat center/contain; } }
    .result_has-auth .result__text {
      padding: 0;
      width: pxvh(420);
      height: pxvh(34);
      text-align: center;
      margin: pxvh(5) auto 0;
      background: url(../../assets/img/xlHasAuthHighlight.svg) no-repeat center/contain; }

    .result_rating .result__content {
      width: pxvh(600);
      height: pxvh(242);
      margin-top: pxvh(88);
      background: url(../../assets/img/xlRatingBg.svg) no-repeat center/contain;
      &:before {
        top: pxvh(-58);
        left: pxvh(196);
        width: pxvh(208);
        height: pxvh(100);
        background: url(../../assets/img/projectLogo.svg) no-repeat center/contain; } }
    .result_rating .result__title {
      width: pxvh(402);
      height: pxvh(80);
      margin: pxvh(62) auto 0;
      font-size: pxvh(30);
      line-height: pxvh(40); }
    .result_rating .result__subtitle {
      margin-top: pxvh(6); }
    .result_rating .result__play-again.thin {
      margin-top: pxvh(38); }
    .result_rating .result__rules {
      margin-top: pxvh(44); } } }
@each $provider in $authProviders {
  .result__social_auth .result__social-btn_#{$provider} {
    background-image: url('../../assets/img/auth_#{$provider}.svg'); } }
@each $provider in $shareProviders {
  .result__social_share .result__social-btn_#{$provider} {
    background-image: url('../../assets/img/share_#{$provider}.svg'); } }

.result-item {
  display: flex;
  background: #fff;
  align-items: center;
  box-sizing: border-box;
  &:last-child {
    margin-bottom: 0; }
  &__place {
    color: #FFF;
    font-style: normal;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-family: 'Peach Milk 2.0', sans-serif;
    background: url(../../assets/img/ratingBg.svg) no-repeat center/contain;
    .owned & {
      background-image: url(../../assets/img/ratingBgOwned.svg); } }
  &__name {
    color: #282828;
    font-style: normal;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Open Sans', sans-serif; }
  &__score {
    color: #070E27;
    flex-shrink: 0;
    margin-left: auto;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-family: 'Peach Milk 2.0', sans-serif; }

  @include mediaRapture('mobile') {
    height: pxvw(61);
    padding: pxvw(15);
    border-radius: pxvw(8);
    margin-bottom: pxvw(10);
    &__place {
      width: pxvw(31);
      height: pxvw(31);
      line-height: pxvw(31);
      margin-right: pxvw(10); }
    &__name {
      font-size: pxvw(12);
      line-height: pxvw(24); }
    &__score {
      font-size: pxvw(14);
      line-height: pxvw(10); } }

  @include mediaRapture('desktop') {
    height: pxvh(72);
    padding: pxvh(16) pxvh(30) pxvh(16) pxvh(16);
    border-radius: pxvh(8);
    margin-bottom: pxvh(10);
    &__place {
      width: pxvh(31);
      height: pxvh(31);
      line-height: pxvh(31);
      margin-right: pxvh(10); }
    &__name {
      font-size: pxvh(20);
      line-height: pxvh(24); }
    &__score {
      font-size: pxvh(28);
      line-height: pxvh(28); } } }
