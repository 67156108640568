@import './mixins';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

html, body, #root, .app {
  width: 100%;
  height: 100%; }
body {
  top: 0;
  left: 0;
  margin: 0;
  position: fixed;
  font-family: 'Open Sans', sans-serif; }
button {
  border: 0;
  padding: 0;
  outline: none;
  display: block;
  cursor: pointer;
  background: transparent;
  -webkit-tap-highlight-color: transparent; }
@font-face {
  font-family: 'Peach Milk 2.0';
  src: url('./assets/fonts/PeachMilk20.eot');
  src: url('./assets/fonts/PeachMilk20.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/PeachMilk20.woff2') format('woff2'), url('./assets/fonts/PeachMilk20.woff') format('woff'), url('./assets/fonts/PeachMilk20.ttf') format('truetype');
  font-weight: normal;
  font-style: normal; }

@include mediaRapture('mobile') {
  body {
    background: url(./assets/img/bg.jpg) no-repeat center/cover; } }

@include mediaRapture('desktop') {
  body {
    background: url(./assets/img/xlBg.jpg) no-repeat center/cover; } }
