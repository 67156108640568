@import './mixins';

.score {
  position: absolute;
  &:after {
    content: '';
    display: block;
    position: absolute;
    background: url(./assets/img/scoreParticles.svg) repeat-y center/contain; }
  &__bg {
    position: absolute;
    background: url(./assets/img/scoreBg.svg) no-repeat center right/contain; }
  &__icon {
    position: absolute;
    filter: drop-shadow(0 0 10px #FFD600);
    background: url(./assets/img/coin.svg) no-repeat center/contain; }
  &__value {
    position: absolute;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-family: 'Peach Milk 2.0', sans-serif; }
  @include mediaRapture('mobile') {
    top: pxvw(17);
    right: pxvw(24);
    min-width: pxvw(86);
    height: pxvw(40);
    &:after {
      top: 0;
      left: pxvw(-15);
      width: pxvw(53);
      height: pxvw(40);
      animation: 200s btnParticles 0s infinite linear; }
    &__icon {
      top: 0;
      left: 0;
      height: 100%;
      width: pxvw(31); }
    &__bg {
      right: 0;
      top: pxvw(7);
      width: pxvw(76);
      height: pxvw(29); }
    &__value {
      top: pxvw(17);
      left: pxvw(39);
      font-size: pxvw(14);
      line-height: pxvw(10); } }
  @include mediaRapture('desktop') {
    top: pxvh(38);
    right: pxvh(183);
    height: pxvh(69);
    min-width: pxvh(160);
    &:after {
      top: 0;
      height: 100%;
      left: pxvh(-27);
      width: pxvh(85);
      animation: 200s btnParticles 0s infinite linear; }
    &__icon {
      top: 0;
      left: 0;
      height: 100%;
      width: pxvh(58); }
    &__bg {
      right: 0;
      top: pxvh(10);
      width: pxvh(143);
      height: pxvh(53); }
    &__value {
      top: pxvh(22);
      left: pxvh(73);
      font-size: pxvh(28);
      line-height: pxvh(28); } } }

.header {
  &__left_logo,
  &__right_logo {
    transform: scale(1);
    will-change: transform;
    transition: transform 200ms ease;
    &:hover {
      transform: scale(1.15); } }
  @include mediaRapture('mobile') {
    &__left_logo,
    &__right_logo {
      top: pxvw(20);
      left: pxvw(48);
      display: block;
      width: pxvw(102);
      height: pxvw(102);
      position: absolute;
      -webkit-tap-highlight-color: transparent;
      &:after,
      &:before {
        content: '';
        display: block;
        position: absolute; }
      &:before {
        width: 100%;
        height: 100%;
        filter: drop-shadow(pxvw(-8) pxvw(-8) pxvw(15) #2196F3);
        background: url(./assets/img/leftLogoBg.svg) no-repeat center/contain; }
      &:after {
        top: pxvw(28);
        left: pxvw(15);
        width: pxvw(71);
        height: pxvw(45);
        background: url(./assets/img/leftLogo.svg) no-repeat center/contain; } }
    &__right_logo {
      left: auto;
      right: pxvw(48);
      &:before {
        filter: drop-shadow(pxvw(5) pxvw(5) pxvw(15) #FFF12D);
        background: url(./assets/img/rightLogoBg.svg) no-repeat center/contain; }
      &:after {
        top: pxvw(20);
        left: pxvw(22);
        width: pxvw(57);
        height: pxvw(55);
        background: url(./assets/img/rightLogo.svg) no-repeat center/contain; } } }
  @include webview() {
    &__left_logo,
    &__right_logo {
      left: pxvw(54);
      width: pxvw(90);
      height: pxvw(90);
      &:after {
        top: pxvw(24);
        left: pxvw(13);
        width: pxvw(63);
        height: pxvw(50); } }
    &__right_logo {
      left: auto;
      right: pxvw(54);
      &:after {
        top: pxvw(18);
        left: pxvw(19.5);
        width: pxvw(50);
        height: pxvw(48.5); } } }

  @include mediaRapture('desktop') {
    display: flex;
    margin-top: pxvh(62);
    justify-content: center;
    &__left_logo,
    &__right_logo {
      display: block;
      width: pxvh(102);
      height: pxvh(102);
      position: relative;
      -webkit-tap-highlight-color: transparent;
      &:after,
      &:before {
        content: '';
        display: block;
        position: absolute; }
      &:before {
        width: 100%;
        height: 100%;
        filter: drop-shadow(pxvh(-8) pxvh(-8) pxvh(15) #2196F3);
        background: url(./assets/img/leftLogoBg.svg) no-repeat center/contain; }
      &:after {
        top: pxvh(28);
        left: pxvh(15);
        width: pxvh(71);
        height: pxvh(47);
        background: url(./assets/img/leftLogo.svg) no-repeat center/contain; } }
    &__right_logo {
      margin-left: pxvh(40);
      &:before {
        filter: drop-shadow(pxvw(5) pxvw(5) pxvw(15) #FFF12D);
        background: url(./assets/img/rightLogoBg.svg) no-repeat center/contain; }
      &:after {
        top: pxvh(20);
        left: pxvh(22);
        width: pxvh(57);
        height: pxvh(55);
        background: url(./assets/img/rightLogo.svg) no-repeat center/contain; } } } }
.round {
  color: #000000;
  position: absolute;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-family: 'Peach Milk 2.0', sans-serif;
  background: url(./assets/img/roundBg.svg) no-repeat center/contain;
  @include mediaRapture('mobile') {
    width: pxvw(149);
    height: pxvw(54);
    font-size: pxvw(24);
    line-height: pxvw(54);
    filter: drop-shadow(0px pxvw(20) pxvw(12) rgba(0, 0, 0, 0.25)); }
  @include mediaRapture('desktop') {
    width: pxvh(195);
    height: pxvh(70);
    font-size: pxvh(32);
    line-height: pxvh(70);
    filter: drop-shadow(0px pxvh(20) pxvh(12) rgba(0, 0, 0, 0.25)); } }
.coin-prefab {
  top: 0;
  left: 0;
  opacity: 0;
  display: none;
  position: absolute;
  transform-origin: 50% 50%;
  will-change: transform, opacity;
  transition: opacity 300ms ease-in;
  background: url(./assets/img/coinAnim.svg) no-repeat center/contain;
  &_animate {
    opacity: 1;
    z-index: 10;
    display: block; }
  @include mediaRapture('desktop') {
    width: pxvh(30);
    height: pxvh(30); }
  @include mediaRapture('mobile') {
    width: pxvw(24);
    height: pxvw(24); } }

.sound-switch {
  z-index: 99;
  position: absolute;
  &_enabled {
    background: url(./assets/img/soundSwitchEnabled.svg) no-repeat center/contain; }
  &_disabled {
    background: url(./assets/img/soundSwitchDisabled.svg) no-repeat center/contain; }
  @include mediaRapture('mobile') {
    width: pxvw(48);
    right: pxvw(20);
    bottom: pxvw(20);
    height: pxvw(28); }
  @include mediaRapture('desktop') {
    width: pxvh(82);
    right: pxvh(60);
    bottom: pxvh(60);
    height: pxvh(48); } }
