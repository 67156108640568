@import '../../mixins';

.title-screen {
  @include mediaRapture('mobile') {
    &__content {
      color: #282828;
      top: pxvw(192);
      left: pxvw(26);
      width: pxvw(266);
      height: pxvw(198);
      position: absolute;
      text-align: center;
      font-family: 'Peach Milk 2.0', sans-serif;
      &:after,
      &:before {
        top: 0;
        left: 0;
        content: '';
        display: block;
        position: absolute; }
      &:before {
        width: 100%;
        height: 100%;
        filter: drop-shadow(0 pxvw(40) pxvw(30) rgba(0, 0, 0, 0.25)) drop-shadow(0 pxvw(40) pxvw(8) rgba(0, 0, 0, 0.15));
        background: url(../../assets/img/titleContentBg.svg) no-repeat center/contain; }
      &:after {
        top: pxvw(-39);
        left: pxvw(20);
        width: pxvw(228);
        height: pxvw(111);
        background: url(../../assets/img/projectLogo.svg) no-repeat center/contain; } }
    &__title {
      position: relative;
      margin-top: pxvw(81);
      font-size: pxvw(32);
      line-height: pxvw(32); }
    &__text {
      width: pxvw(200);
      position: relative;
      font-size: pxvw(12);
      line-height: pxvw(17);
      margin: pxvw(12) auto 0;
      font-family: 'Open Sans', sans-serif; }
    &__btn {
      left: pxvw(65);
      bottom: pxvw(40);
      position: absolute;
      width: pxvw(190);
      height: pxvw(134);
      will-change: transform;
      transition: transform 200ms ease-in-out;
      &:active {
        transform: scale(1.15); }
      &:active:after {
        opacity: 0; }
      &-text {
        color: #fff;
        position: relative;
        font-size: pxvw(32);
        line-height: pxvw(32);
        letter-spacing: 0.1em;
        text-transform: uppercase;
        font-family: 'Peach Milk 2.0', sans-serif; }
      &:after,
      &:before {
        top: 0;
        left: 0;
        content: '';
        display: block;
        position: absolute; }
      &:before {
        top: pxvw(30);
        width: pxvw(190);
        height: pxvw(77);
        background: url(../../assets/img/playBtn.svg) no-repeat center/contain; }
      &:after {
        width: 100%;
        height: 100%;
        background: url(../../assets/img/playBtnParticles.svg) repeat-y center/contain;
        animation: 100s btnParticles 0s infinite linear; } }
    .rules-link {
      position: absolute;
      bottom: pxvw(12);
      left: 50%;
      transform: translateX(-50%); } }

  @include webview() {
    .rules-link {
      bottom: pxvw(2); }
    &__content {
      top: pxvw(160); }
    &__btn {
      bottom: 0; } }

  @include mediaRapture('desktop') {
    .rules-link {
      margin-top: pxvh(56); }
    &__content {
      display: flex;
      width: pxvh(441);
      height: pxvh(272);
      position: relative;
      flex-direction: column;
      margin: pxvh(53) auto 0;
      &:after,
      &:before {
        top: 0;
        left: 0;
        content: '';
        display: block;
        position: absolute; }
      &:before {
        width: 100%;
        height: 100%;
        filter: drop-shadow(0 pxvh(40) pxvh(30) rgba(0, 0, 0, 0.25)) drop-shadow(0 pxvh(40) pxvh(8) rgba(0, 0, 0, 0.15));
        background: url(../../assets/img/xlTitleContentBg.svg) no-repeat center/contain; }
      &:after {
        top: pxvh(-39);
        left: pxvh(51);
        width: pxvh(339);
        height: pxvh(165);
        background: url(../../assets/img/projectLogo.svg) no-repeat center/contain; } }
    &__title {
      position: relative;
      text-align: center;
      font-size: pxvh(40);
      line-height: pxvh(32);
      margin-top: pxvh(148);
      letter-spacing: 0.05em;
      font-family: 'Peach Milk 2.0', sans-serif; }
    &__text {
      width: pxvh(201);
      font-style: normal;
      font-weight: normal;
      position: relative;
      text-align: center;
      font-size: pxvh(12);
      line-height: pxvh(17);
      margin: pxvh(12) auto;
      font-family: 'Open Sans', sans-serif; }
    &__btn {
      width: pxvh(247);
      height: pxvh(100);
      position: relative;
      margin: pxvh(31) auto 0;
      will-change: transform;
      transition: transform 200ms ease-in-out;
      background: url(../../assets/img/playBtn.svg) no-repeat center/contain;
      &:hover {
        transform: scale(1.15); }
      &-text {
        color: #fff;
        position: relative;
        font-size: pxvh(48);
        line-height: pxvh(32);
        letter-spacing: 0.1em;
        text-transform: uppercase;
        font-family: 'Peach Milk 2.0', sans-serif; }
      &:after {
        top: 0;
        left: 0;
        content: '';
        display: block;
        position: absolute; }
      &:after {
        width: 100%;
        height: 100%;
        background: url(../../assets/img/playBtnParticles.svg) repeat-y center/contain;
        animation: 100s btnParticles 0s infinite linear; }
      &:hover {
        transform: scale(1.15); }
      &:hover:after {
        opacity: 0; } } } }

@keyframes btnParticles {
  from {
    background-position-y: 0px; }
  to {
    background-position-y: -10000px; } }
